@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&family=Noto+Sans+JP:wght@500&display=swap');

body {
  margin: 0;
  font-family: 'M PLUS Rounded 1c', sans-serif;
}

button {
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
