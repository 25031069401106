button.今日花金？ {
  padding: 20px;

  color: #000;
  background-color: #fff100;
}

button.もう一度聞く {
  padding: 20px;

  display: flex;
  flex-direction: column;
}
