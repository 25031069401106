.answer {
  position: relative;
  display: inline-block;
  margin: 20px;
  padding: 20px;
  min-width: 120px;
  max-width: 100%;
  color: #555;
  font-size: 16px;
  background: #fff;
  border: solid 3px #555;
  box-sizing: border-box;
  border-radius: 15px;

  font-size: 20px;
}

.answer:before {
  content: '';
  position: absolute;
  top: 50%;
  left: -24px;
  margin-top: -12px;
  border: 12px solid transparent;
  border-right: 12px solid #fff;
  z-index: 2;
}

.answer:after {
  content: '';
  position: absolute;
  top: 50%;
  left: -30px;
  margin-top: -14px;
  border: 14px solid transparent;
  border-right: 14px solid #555;
  z-index: 1;
}

.answer p {
  margin: 0;
  padding: 0;
}
